import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import {StyleSheet, Text} from 'react-native';
import {Icon} from 'react-native-elements';
import 'rsuite/dist/styles/rsuite-default.css';

type priceProps = {
  data: {tableHeader?: any[]; listData?: any[]};
  cellStyle: any;
  rowStyle: any;
};

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export const TableV = (props: priceProps) => {
  // const {data, cellStyle, rowStyle} = props;

  // const _getServices = (list: any[]) => {
  //   const _data: any = [];
  //   // eslint-disable-next-line no-unused-expressions
  //   Array.isArray(list) &&
  //     list.forEach(item => {
  //       console.log('list', item);
  //       _data.push(
  //         <TableRow key={item.title}>
  //           <TableCell style={StyleSheet.flatten(rowStyle)}>
  //             {item.title}
  //             <br />
  //             {item.subTitle}
  //           </TableCell>
  //           <TableCell align="right">
  //             {item?.icon1 ? <Icon icon={item.icon1} /> : null}
  //           </TableCell>
  //           <TableCell align="right">
  //             {item?.icon2 ? <Icon icon={item.icon2} /> : null}
  //           </TableCell>
  //           <TableCell align="right">
  //             {item?.icon3 ? <Icon icon={item.icon3} /> : null}
  //           </TableCell>
  //         </TableRow>,
  //       );
  //     });

  //   return _data;
  // };

  // const _getPlans = () => {
  //   const _dataHeader: any = [];
  //   // eslint-disable-next-line no-unused-expressions

  //   Array.isArray(data?.tableHeader) &&
  //     data?.tableHeader.forEach(ele => {
  //       _dataHeader.push(<TableCell style={{textAlign:'center'}}>{ele?.label}</TableCell>);
  //     });
  //   return (
  //     <TableHead>
  //       <TableRow style={StyleSheet.flatten(cellStyle)}>{_dataHeader}</TableRow>
  //       <TableBody>{_getServices(data?.dataList)}</TableBody>
  //     </TableHead>
  //   );
  // };

  // return (
  //   <TableContainer component={Paper}>
  //     <Table aria-label="customized table">{_getPlans()}</Table>
  //   </TableContainer>
  // );
  const styles = {
    headerStyle: {
      fontSize: 18,
      color: '#fff',
    },
  };
  return (
    <TableContainer style={{marginBottom: 20}}>
      <Table
        aria-label="spanning table"
        align="center"
        style={{
          width: '87%',
          borderLeft: '1px solid rgba(74,117,147, 0.1)',
          borderRight: '1px solid rgba(74,117,147, 0.1)',
          borderBottom: '1px solid rgba(74,117,147, 0.1)',
        }}
        // border={1}
      >
        <TableHead>
          <TableRow
            style={{
              // backgroundColor: '#F5F7FA',
              backgroundColor: '#4a7593',

              fontSize: 20,
              fontWeight: 'bold',
            }}
          >
            {props?.data?.tableHeader?.map(item => {
              return (
                <TableCell
                  align="center"
                  style={StyleSheet.flatten([styles.headerStyle])}
                >
                  {item?.label}
                </TableCell>
              );
            })}
            {/* <TableCell
              align="center"
              style={StyleSheet.flatten([styles.headerStyle])}
            >
              Feature Category
            </TableCell>
            <TableCell
              align="center"
              style={StyleSheet.flatten([styles.headerStyle])}
            >
              Feature
            </TableCell>
            <TableCell
              align="center"
              style={StyleSheet.flatten([styles.headerStyle])}
            >
              Bronze
            </TableCell>
            <TableCell
              align="center"
              style={StyleSheet.flatten([styles.headerStyle])}
            >
              Silver
            </TableCell>
            <TableCell
              align="center"
              style={StyleSheet.flatten([styles.headerStyle])}
            >
              Gold
            </TableCell>
            <TableCell
              align="center"
              style={StyleSheet.flatten([styles.headerStyle])}
            >
              Industry Example - Education
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={2} style={{textAlign: 'center'}}>
            Authenticity
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              NFC/RFID
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              Contact Us
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              Contact Us
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              Contact Us
            </TableCell>
          </TableRow>
          <TableRow style={{backgroundColor: '#F5F7FA'}}>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              QR/Bar Code
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
              rowSpan={6}
            >
              Product Journey
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              Origin Source
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow style={{backgroundColor: '#F5F7FA'}}>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
             # of Products
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            10
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
             20
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              30
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              # of Batches
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            10
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            100
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              200
            </TableCell>
          </TableRow>
          <TableRow style={{backgroundColor: '#F5F7FA'}}>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              Supply Chain Journey - Steps
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              1
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              3
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              5
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              Supply Chain Journey - Visual Map
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow style={{backgroundColor: '#F5F7FA', borderLeft: '1px solid rgba(74,117,147, 0.1)',}}>
            <TableCell style={{textAlign: 'center'}}>
              Decentralized ID Proof
            </TableCell>
            <TableCell style={{textAlign: 'center'}}>
            <Icon
                name={'close-circle-outline'}
                type={'material-community'}
                color={'#d2d3d2'}
                size={30}
              />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
              rowSpan={4}
            >
              Customer Engagement
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              Product Story
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow style={{backgroundColor: '#F5F7FA'}}>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
             Real-Time Feedback
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            <Icon
                name={'close-circle-outline'}
                type={'material-community'}
                color={'#d2d3d2'}
                size={30}
              />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
             <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              Social Media Share
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow style={{backgroundColor: '#F5F7FA'}}>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              Personalization 
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon
                name={'close-circle-outline'}
                type={'material-community'}
                color={'#d2d3d2'}
                size={30}
              />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon
                name={'close-circle-outline'}
                type={'material-community'}
                color={'#d2d3d2'}
                size={30}
              />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
              rowSpan={2}
            >
              Standards/Ecosystem Connect
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              GS1 Standards
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon
                name={'close-circle-outline'}
                type={'material-community'}
                color={'#d2d3d2'}
                size={30}
              />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow style={{backgroundColor: '#F5F7FA'}}>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
             REST APIs
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            <Icon
                name={'close-circle-outline'}
                type={'material-community'}
                color={'#d2d3d2'}
                size={30}
              />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
             <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
              rowSpan={4}
            >
             Technology 
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              Consumer Mobile App
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
             <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow style={{backgroundColor: '#F5F7FA'}}>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
             Analytics Dashboard
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            <Icon
                name={'close-circle-outline'}
                type={'material-community'}
                color={'#d2d3d2'}
                size={30}
              />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
             <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow style={{backgroundColor: '#F5F7FA'}}>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            API for Ecosystem Players
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            <Icon
                name={'close-circle-outline'}
                type={'material-community'}
                color={'#d2d3d2'}
                size={30}
              />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
             <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow style={{backgroundColor: '#F5F7FA'}}>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            Web Apps for Producers
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
            <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
             <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              <Icon name={'check-circle'} type={'feather'} color={'#3B7696'} />
            </TableCell>
          </TableRow>
          <TableRow style={{backgroundColor: '#3B7696'}}>
            <TableCell
              style={{textAlign: 'center', color: '#fff', fontWeight: 'bold'}}
            >
              Price / Month
            </TableCell>
            <TableCell
              style={{textAlign: 'center', color: '#fff', fontWeight: 'bold'}}
            >
              <Icon name={'dash'} type={'octicon'} color={'#d2d3d2'} />
            </TableCell>
            <TableCell
              style={{textAlign: 'center', color: '#fff', fontWeight: 'bold'}}
            >
              <Text
                style={{
                  lineHeight: 2,
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                $ 100 / month
              </Text>
            </TableCell>
            <TableCell
              style={{textAlign: 'center', color: '#fff', fontWeight: 'bold'}}
            >
              <Text
                style={{
                  lineHeight: 2,
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                $ 400 / month
              </Text>
            </TableCell>
            <TableCell
              style={{textAlign: 'center', color: '#fff', fontWeight: 'bold'}}
            >
              <Text
                style={{
                  lineHeight: 2,
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                Contact Us
              </Text>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={3} style={{textAlign: 'center'}}>
              Add-Ons
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              White Label Consumer App{' '}
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              Contact Us
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              Contact Us
            </TableCell>
            <TableCell
              style={{
                textAlign: 'center',
                borderLeft: '1px solid rgba(74,117,147, 0.1)',
              }}
            >
              Contact Us
            </TableCell>
          </TableRow>
          </TableBody>
      </Table>
    </TableContainer>
  );
};
