import gql from 'graphql-tag';

export const UPDATE_BATCH = gql`
mutation updateBatch($where:FindUniqueBranch, $data:UpdateBatchInput){
    updateBatch(
        where:$where
        data:$data
        ){
        _id
    }}
`;

export const GET_QR_CODE = gql`
mutation getQRCode($appMode:AppMode!, $gtin: String!, $generatePdf: Boolean, $batch: String){
    encodeBarcode(
        appMode: $appMode,
        gtin: $gtin,
        generatePdf: $generatePdf,
        batch: $batch
    ){
        pdfBase64
    }
}
`;

export const FORGOT_PASSWORD = gql`
    mutation forgotPassword($email: String){
        resetPassword(
            email: $email
        )
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation resetPassword($email: String!, $password: String!, $passwordResetToken: String!){
        changePassword(
            email: $email
            password: $password
            passwordResetToken: $passwordResetToken
        )
    }
`;