import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { LoginPageLG } from './LoginPage.lg';
import {LoginPageSM} from './LoginPage.sm';

export interface IProps {
  onLogin?(email?: string, password?: string, rememberMe?: boolean): void;
  forgotPasswordPressed?(): void;
}

export const LoginPage = (props: IProps) => {
  const { bp } = useViewport();

  if (bp === 'xs' || bp === 'sm') {
    return <LoginPageSM {...props} />;
  }

  // if (bp === 'md') {
  //   return <div />;
  // }

  //bp === 'lg' || bp === 'xl' || bp === 'md
  return <LoginPageLG {...props} />;
};
