import React from 'react';
import {View} from 'react-native';
import {PricingDetail, PricingSlot, PricingTable} from 'react-pricing-table';

export interface IProps {
  pricingData?: any[];
}

export const PricingTableV = (props: IProps) => {
  const {pricingData} = props;
  const getPricingSlots = () => {
    let _priceList =
      Array.isArray(pricingData) &&
      pricingData?.map(item => {
        return (
          <PricingSlot
            buttonText={item?.buttonText}
            title={item?.title}
            priceText={item?.priceText}
            style={{minHeight: 430}}
          // highlighted={true}
          >
            {item?.details?.map((ele: string) => {
              return <PricingDetail>{ele}</PricingDetail>;
            })}
          </PricingSlot>
        );
      });
    return _priceList;
  };

  return (
    <View style={{paddingLeft: '5%', paddingRight: '5%'}}>
      <PricingTable highlightColor="#4a7593">{getPricingSlots()}</PricingTable>
    </View>
  );
};
